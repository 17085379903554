import React from "react"
import { Text, Divider } from "theme-ui"

import Part from "../../../components/adventures/short-stories/Part"
import Section from "../../../components/adventures/Section"
import Block from "../../../components/adventures/Block"

export default function Part02() {
  return (
    <Part number="2" title="The Gregarious Gate" time="10–15 minutes">
      <Section type="Setup">
        <Block type="Secret Context" title="Setup">
          <Text>In this scene, the players will have to gain entry to Mischief Mountain by answering a series of riddles posed by a sentient stone door named Stonebrow. When Stonebrow lets the players through, they will see the entrance to a dark cave system that seems to lead up into the mountain.</Text>
        </Block>
      </Section>

      <Section type="Context">  
        <Block type="Exposition" title="The Winding Ridge">
          <Text>You leave Treasure Town and begin to climb a winding ridge on the steep mountainside. Small rocks and pebbles occasionally tumble down the rocky cliff and crash on the path around you. You can tell that it would be nearly impossible to climb the side of the mountain.</Text>
          <Text>After walking for a short while, the path curves into the face of the mountain and ends at a gigantic stone door. The door has a large face that seems to be alive. It appears to be sleeping.</Text>
        </Block>
      </Section>

      <Section type="Encounter">
        <Block type="Secret Context" title="Encounter: Stonebrow">
          <Text>Stonebrow is a sentient door that was created by The Trickster to guard the approach to the mountain. They are a kindly door who loves meeting adventurers and presenting them with riddles. Stonebrow appreciates nothing more than hearing a silly joke. They speak in a low voice, and laugh with a hearty rumble.</Text>
          <Text>Stonebrow prefers to have their riddles solved before allowing adventurers to pass, but they have grown quite lonely over the years. Even if the players solve all of Stonebrow's riddles, the door will sigh and express regret that the adventurers must leave so soon.</Text>
          <Text>If the adventurers are kind to the door and say nice things to it, Stonebrow will smile at them and open. If the players fail to answer any of the riddles, Stonebrow will sigh and say that they will be allowed to pass if they tell them a joke or a story instead.</Text>
          <Text>If the adventurers are mean to the door, Stonebrow will appear to cry, and their face will fade away. The door will stay locked until the players apologize.</Text>
        </Block>

        <Block type="Open Context" title="Stonebrow's Riddles">
          <Text>Q: I wear a cap, but it's not a hat. What am I?</Text>
          <Text>A: A mountain.</Text>
          <Divider sx={{ my: [2, 2], borderBottom: "1px dashed" }} />
          <Text>Q: I'm taller than a tower, but older than any building. What am I?</Text>
          <Text>A: A mountain.</Text>
          <Divider sx={{ my: [2, 2], borderBottom: "1px dashed" }} />
          <Text>Q: I can be climbed, but I'm not a ladder. What am I?</Text>
          <Text>A: A mountain.</Text>
          <Divider sx={{ my: [2, 2], borderBottom: "1px dashed" }} />
          <Text>Q: I can rock, but I can't roll. What am I?</Text>
          <Text>A: A mountain.</Text>
        </Block>
      </Section>
    </Part>
  )
}
